import PropertiesStyles from "./properties.module.css";
import { NavBar } from "../../components/layout/navbar/navbar";
import { Faq } from "../../components/faq/faq";
import { PropertyCard } from "../../components/propertyCard/propertyCard";
import { propertiesData } from "../../mockData/properties";
import { urlConfig } from "../../config/urlConfig";
import React, { useEffect, useState } from "react";

const Properties = () => {
  const [allProperties, setAllProperties] = useState(null);

  useEffect(() => {
    getAllproperties();
  }, []);

  const getAllproperties = async () => {
    const response = await fetch(
      `${urlConfig.BASE_URL}/api/properties?populate=*&sort=updatedAt:desc`,
      {
        method: "GET",
        headers: {
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      console.log("An error occured while trying to get all properties");
      return;
    }

    const { data } = await response.json();

    setAllProperties(data);
  };

  return (
    <>
      <header className={PropertiesStyles.hero_section}>
        <NavBar />

        <div className="container h-100">
          <div className={PropertiesStyles.text_section}>
            <h3 className={PropertiesStyles.title}>
              Discover Your Dream Property
            </h3>
            <p className={PropertiesStyles.details}>
              Explore Our Wide Range of Properties and start your journey
              towards a new home.
            </p>
          </div>
        </div>
      </header>

      <section
        className={`${PropertiesStyles.properties_section} mh_white_n_masked`}
      >
        <div className="container">
          {allProperties && (
            <div className="row">
              {allProperties.map(({ attributes }, index) => (
                <div className="col-lg-6 mb-4" key={`${index}`}>
                  <PropertyCard
                    id={index}
                    name={attributes.name}
                    location={attributes.location}
                    photo={attributes.previewImage ? attributes.previewImage[0] : "/default.jpg"}
                    slug={attributes.slug}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <Faq />
    </>
  );
};

export default Properties;
